






































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import EditExperiment from '@/views/experiment/EditExperiment.vue';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import TaskList from '@/components/experiment/TaskList.vue';
import AddTask from '@/components/experiment/AddTask.vue';
import AddGoogleFormsTask from '@/components/experiment/AddGoogleFormsTask.vue';
import AddReviewTask from '@/components/experiment/AddReviewTask.vue';

@Component({
  components: { AddReviewTask, AddGoogleFormsTask, AddTask, EditExperiment, TaskList },
})
export default class AddTasksView extends mixins(Toasts) {
  protected experimentId = -1;
  protected breadcrumbs: { text: string; to?: string; active?: boolean }[] | null = null;
  protected selectedTask = '';

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.experimentId = Number.parseInt(this.$route.params.id);
      if (Number.isNaN(this.experimentId)) {
        this.$router.replace({ path: '/experiment/admin' });
        return;
      }
      this.breadcrumbs = [
        { text: 'Home', to: '/' },
        { text: 'Experiments', to: '/experiment/admin' },
        { text: 'Experiment Details', to: '/experiment/admin/edit/' + this.experimentId },
        { text: 'Add Task', active: true },
      ];
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}
